import request from '@/utils/request'

const robotApi = {
    addRobot: '/admin/AdminTool/add', // 添加机器人
    robotList: '/admin/AdminTool/list', // 机器人列表
    deleteRobot: '/admin/AdminTool/delete', // 删除机器人
    operatoinRobot: '/admin/AdminTool/operate', // 禁止操作
    workRobot: '/admin/AdminTool/workList', // 预约时间点列表
    disableRobot: '/admin/AdminTool/disable', // 是非vip预留
    changeRobot:'/admin/AdminTool/edit',
    ruleRobot:'/admin/RobotType/list',//robot规则



    modelList:'/admin/model/list',
   modelAdd:'/admin/model/add',
   modelEdit:'/admin/model/edit',
   modelInfo:'/admin/model/info',
   modelDisabled:'/admin/model/disabled',
   modelTypeList:'/admin/model/modelTypeList',

   newrobotList :'/admin/robot/list',
   newrobotAdd:'/admin/robot/add',
   newrobotEdit:'/admin/robot/edit',
   newrobotInfo:'/admin/robot/info',
   newrobotDesabled:'/admin/robot/disabled',

   getModelSupportData:'/admin/model/getModelSupportData',
   getStepValue:'/admin/model/getStepValue'
}
/**
 * login func
 * parameter: {
 *     username: '',
 *     password: '',
 * }
 * @param parameter
 * @returns {*}
 */

export function getModelSupportData(parameter) {
    return request({
      //  url: "http://localhost:8080/area/hot",
        //url:robotApi.ruleRobot,
        url:robotApi.getModelSupportData,
        method: 'get',
        params: parameter
    })
}


export function getStepValue(parameter) {
    return request({
      //  url: "http://localhost:8080/area/hot",
        //url:robotApi.ruleRobot,
        url:robotApi.getStepValue,
        method: 'get',
        params: parameter
    })
}




 export function modelList(parameter) {
    return request({
      //  url: "http://localhost:8080/area/hot",
        //url:robotApi.ruleRobot,
        url:robotApi.modelList,
        method: 'get',
        params: parameter
    })
}
export function modelInfo(parameter) {
    return request({
      //  url: "http://localhost:8080/area/hot",
        //url:robotApi.ruleRobot,
        url:robotApi.modelInfo,
        method: 'get',
        params: parameter
    })
}

export function modelTypeList(parameter) {
    return request({
      //  url: "http://localhost:8080/area/hot",
        //url:robotApi.ruleRobot,
        url:robotApi.modelTypeList,
        method: 'get',
        params: parameter
    })
}
export function modelAdd(parameter) {
    return request({
        url: robotApi.modelAdd,
        method: 'post',
        data: parameter
    })
}
export function modelEdit(parameter) {
    return request({
        url: robotApi.modelEdit,
        method: 'post',
        data: parameter
    })
}
export function modelDisabled(parameter) {
    return request({
        url: robotApi.modelDisabled,
        method: 'post',
        data: parameter
    })
}






export function newrobotList(parameter) {
    return request({
      //  url: "http://localhost:8080/area/hot",
        //url:robotApi.ruleRobot,
        url:robotApi.newrobotList,
        method: 'get',
        params: parameter
    })
}
export function newrobotAdd(parameter) {
    return request({
        url: robotApi.newrobotAdd,
        method: 'post',
        data: parameter
    })
}
export function newrobotEdit(parameter) {
    return request({
        url: robotApi.newrobotEdit,
        method: 'post',
        data: parameter
    })
}

export function newrobotInfo(parameter) {
    return request({
      //  url: "http://localhost:8080/area/hot",
        //url:robotApi.ruleRobot,
        url:robotApi.newrobotInfo,
        method: 'get',
        params: parameter
    })
}
export function newrobotDesabled(parameter) {
    return request({
        url: robotApi.newrobotDesabled,
        method: 'post',
        data: parameter
    })
}









 export function addRobot(parameter) {
    return request({
        url: robotApi.addRobot,
        method: 'post',
        data: parameter
    })
}

export function changeRobot(parameter) {
    return request({
        url: robotApi.changeRobot,
        method: 'get',
        params: parameter
    })
}
export function changeRobotUp(parameter) {
    return request({
        url: robotApi.changeRobot,
        method: 'post',
        data: parameter
    })
}
 export function robotList(parameter) {
    return request({
        url: robotApi.robotList,
        method: 'get',
        params: parameter
    })
}
export function deleteRobot(parameter) {
    return request({
        url: robotApi.deleteRobot,
        method: 'post',
        data: parameter
    })
}
export function operatoinRobot(parameter) {
    return request({
        url: robotApi.operatoinRobot,
        method: 'post',
        data: parameter
    })
}
export function workRobot(parameter) {
    return request({
        url: robotApi.workRobot,
        method: 'get',
        params: parameter
    })
}
export function disableRobot(parameter) {
    return request({
        url: robotApi.disableRobot,
        method: 'post',
        data: parameter
    })
}